import React from "react";
import Image from "./common/image";
import ScreenWrapper from "./common/screen-wrapper";
import SectionTitle from "./common/section-title";
import FTContent from "../resources/content/favorite-things-content.json";
import FTTopics from "../resources/content/favorite-things-topics.json";
import "../styles";

function GetFilteredContent(slug) {
  return FTContent.filter((content) => content.topic === slug);
}

const FTImageSize = 150;

const FavoriteThingCard = ({ item, position }) => (
  <div className={`ft-card flex-row flex-center ${position}`}>
    {position === "left" && (
      <Image
        height={FTImageSize}
        width={FTImageSize}
        source={item.image}
        style={{ marginRight: 20 }}
      />
    )}
    <div>
      <div className="ft-card-title roboto font-size-27 underline font-color-p-gray bold">
        {item.topic}
      </div>
      <div className="roboto font-size-22 font-color-p-gray ft-card-list flex-column">
        {GetFilteredContent(item.slug).map((content) => (
          <a
            className="ft-card-link"
            href={content.link}
            target="_blank"
            rel="noopener noreferrer"
            key={content.id}
          >
            {content.text}
          </a>
        ))}
      </div>
    </div>
    {position === "right" && (
      <Image
        height={FTImageSize}
        width={FTImageSize}
        source={item.image}
        style={{ marginLeft: 20 }}
      />
    )}
  </div>
);

const FavoriteThings = () => (
  <ScreenWrapper>
    <div className="favorite-things-buffer" />
    <SectionTitle intro="favorite things" title="what i enjoy" />
    <div className="ft-cards-container">
      {FTTopics.map((item) => (
        <FavoriteThingCard item={item} position={item.style} key={item.id} />
      ))}
    </div>
  </ScreenWrapper>
);

export default FavoriteThings;
