import React from "react";
import ScreenWrapper from "../components/common/screen-wrapper";
import "../styles";
const Footer = () => (
  <ScreenWrapper footer>
    <div className="roboto font-color-white footer-text flex-align flex-end">
      © 2021 copyright all right reserved
    </div>
  </ScreenWrapper>
);

export default Footer;
